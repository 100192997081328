import { Box, ContainerProps } from '@chakra-ui/layout';
import { PropsWithChildren } from 'react';

interface ColumnContainerProps extends PropsWithChildren, ContainerProps {}

const ColumnContainer: React.FC<ColumnContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      display={{ md: 'grid', base: 'flex' }}
      flexDirection="column"
      gridAutoFlow="column"
      gridAutoColumns="1fr"
      gridGap="8"
      alignItems={{ md: 'center', base: 'stretch' }}
      px={{ base: 4, lg: 8 }}
      maxW="container.xl"
      mx="auto"
      py="8"
      className="Section"
      {...props}
    >
      {children}
    </Box>
  );
};

export default ColumnContainer;
